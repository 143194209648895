<template>
  <div
    id="datePicker"
    ref="datePickerWrapper"
    :class="['text-secondary h-fit d-flex flex-column w-100 align-items-center']"
    :data-cy="cyDatePicker"
    :style="{'margin-bottom': -(datepickerSize.height.value - (datepickerSize.height.value*scale)) + 'px'}"

  >
    <div
      v-if="showDateMonth !== undefined"
      :class="['text-1rem ms-4 mb-2 text-start w-100']"
      :data-cy="cyDatePickerTitle"
    >

      <span v-if="type === DATEPICKER_TYPE.WEEK">
        - {{ 'Kalenderwoche' }} {{ calendarWeek }}
      </span>
    </div>
    <div
      v-if="props.showControls"
      id="controls"
      class="d-flex justify-content-between w-100"
    >
      <div
        class="d-flex"
        :data-cy="cyBtnType"
      >
<!--        <BaseButton-->
<!--          :size="btnSizeControls"-->
<!--          :primary="isActive('month')"-->
<!--          :animation="false"-->
<!--          :class="[buttonClass('month'), 'btnControls']"-->
<!--          :data-cy="cyBtnMonth"-->
<!--          @click="changeType('month')"-->
<!--        >-->
<!--          {{ 'Control Months' }}-->
<!--        </BaseButton>-->
<!--        <BaseButton-->
<!--          :size="btnSizeControls"-->
<!--          :primary="isActive('week')"-->
<!--          :animation="false"-->
<!--          :class="[buttonClass('week'), 'btnControls']"-->
<!--          :data-cy="cyBtnWeek"-->
<!--          @click="changeType('week')"-->
<!--        >-->
<!--          {{ 'Control Week' }}-->
<!--        </BaseButton>-->
      </div>
      <div
        class="d-flex"
        :data-cy="cyBtnView"
      >
<!--        <BaseButton-->
<!--          :size="btnSizeControls"-->
<!--          :primary="false"-->
<!--          :animation="false"-->
<!--          :class="['btnControls btn-inactive']"-->
<!--          :data-cy="cyBtnPrev"-->
<!--          @click="setShowDate('prev')"-->
<!--        >-->
<!--          <div class="i-tabler-arrow-left" />-->
<!--        </BaseButton>-->
<!--        <BaseButton-->
<!--          :size="btnSizeControls"-->
<!--          :primary="false"-->
<!--          :animation="false"-->
<!--          :class="['btnControls btn-inactive']"-->
<!--          :data-cy="cyBtnToday"-->
<!--          @click="setShowDate('today')"-->
<!--        >-->
<!--          {{ 'Control Today' }}-->
<!--        </BaseButton>-->
<!--        <BaseButton-->
<!--          :size="btnSizeControls"-->
<!--          :primary="false"-->
<!--          :animation="false"-->
<!--          :class="['btnControls btn-inactive']"-->
<!--          :data-cy="cyBtnNext"-->
<!--          @click="setShowDate('next')"-->
<!--        >-->
<!--          <div class="i-tabler-arrow-right" />-->
<!--        </BaseButton>-->
      </div>
    </div>
    <div
      ref="datepicker"
      :data-cy="cyDatePickerInner"
      class="origin-center-top"
      :class="[sizeStyle]"
      :style="{transform: 'scale(' + scale + ')'}"
    >
      <!-- Wochentage -->
      <div
        class="d-flex origin-top-left"
      >
        <div
          v-for="day in weekdayTopics"
          :key="day"
          :class="[textStyle, 'flex-1 text-center']"
        >
          {{ day }}
        </div>
      </div>
      <div>
        <!-- Monat -->
        <div
          v-if="type === DATEPICKER_TYPE.MONTH && datesArrayMonth !== undefined"
          class="d-flex flex-wrap"
        >
          <div
            v-for="weekday in 7"
            :key="weekday"
            class="w-1/7"
          >
            <div
              v-for="(date, index) in datesArrayMonth[weekday - 1]"
              :key="index"
              :class="datePickerDayOuterSize"
              :data-cy="cyDatePickerMonth"
            >
              <DatePickerDay
                v-if="date !== null"
                :size="props.size"
                :date="date"
                :disabled="props.isDisabled(date)"
                :special-day="getSpecialDay(date)"
                :selected="value ? isSameDay(date, value) : false"
                @click="(day: Date) => clickDay(day)"
              >
                <template #default>
                  {{ date.getDate() }}
                </template>
              </DatePickerDay>
            </div>
          </div>
        </div>
        <!-- WEEK -->
        <div
          v-if="type === DATEPICKER_TYPE.WEEK && datesArrayWeek !== undefined"
          class="d-flex flex-wrap"
        >
          <div
            v-for="weekday in 7"
            :key="weekday"
            class="w-1/7"
          >
            <div
              v-for="(date, index) in datesArrayWeek[weekday - 1]"
              :key="index"
              :class="datePickerDayOuterSize"
              :data-cy="cyDatePickerWeek"
            >
              <DatePickerDay
                v-if="date !== null"
                :size="props.size"
                :date="date"
                :disabled="props.isDisabled(date)"
                :special-day="getSpecialDay(date)"
                :selected="value ? isSameDay(date, value) : false"
                @click="(day: Date) => clickDay(day)"
              >
                <template #default>
                  {{ date.getDate() }}
                </template>
              </DatePickerDay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {format, getISOWeek, isSameDay, isWithinInterval} from 'date-fns'
import { useElementSize } from '@vueuse/core'
import {
  DATEPICKER_SIZE,
  DATEPICKER_TYPE,
  cyBtnMonth,
  cyBtnNext,
  cyBtnPrev,
  cyBtnToday,
  cyBtnType,
  cyBtnView,
  cyBtnWeek,
  cyDatePicker,
  cyDatePickerInner,
  cyDatePickerMonth,
  cyDatePickerTitle,
  cyDatePickerWeek,
  datePickerProps,
} from './interface'
import DatePickerDay from './DatePickerDay.vue'
import {BaseButton} from '../components/base-button'
import {BASE_BUTTON_SIZE} from '../components/base-button/interface'
import {computed, provide, ref, watch} from "vue";

const props = defineProps(datePickerProps)
const type = ref(props.type)
const showDate = ref(props.modelValue)
const emit = defineEmits<{
  (e: 'update:modelValue', value: Date | undefined): void
  (e: 'changeType', value: string): void
}>()
const datepicker = ref(null)
const datePickerWrapper = ref(null)
const datepickerWrapperSize = useElementSize(datePickerWrapper)
const datepickerSize = useElementSize(datepicker)

const value = computed<Date | undefined>({
  set(value) {
    emit('update:modelValue', value)
      document.getElementById('date').value = format(value, 'dd.MM.yyyy');
      document.getElementById('test-button').click()
      document.getElementById('appointment-date-label').innerText = format(value, 'dd.MM.yyyy')
  },
  get() {
    return props.modelValue
  },
})

const btnSizeControls = computed(() => {
  switch (props.size) {
    case DATEPICKER_SIZE.LARGE:
      return BASE_BUTTON_SIZE.MEDIUM
    case DATEPICKER_SIZE.MEDIUM:
      return BASE_BUTTON_SIZE.SMALL
    case DATEPICKER_SIZE.SMALL:
      return BASE_BUTTON_SIZE.SMALL
    default:
      return BASE_BUTTON_SIZE.SMALL
  }
})

const size = computed(() => {
  switch (props.size) {
    case DATEPICKER_SIZE.LARGE:
      return 784
    case DATEPICKER_SIZE.MEDIUM:
      return 672
    case DATEPICKER_SIZE.SMALL:
      return 560
    default:
      return 672
  }
})

const scale = computed(() => {
  return datepickerWrapperSize.width.value / size.value
})

const scaledHeight = computed(() => {
    return datepickerSize.height.value;
})

const months = computed(() => {
  return [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
})

const weekdayTopics = computed(() => {
  return [
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa',
    'So',
  ]
})

const showDateMonth = computed(() => {
  return showDate.value?.getMonth()
})

const showDateYear = computed(() => {
  return showDate.value?.getFullYear()
})

// Alle Tage im Monat z.B. 1 bis 31
const dates = computed(() => {
  if (showDate.value !== undefined) {
    const updatedDates = []
    const currentDay = new Date(showDate.value.getFullYear(), showDate.value.getMonth(), 1, 2, 0, 0, 0)
    while (currentDay.getMonth() === showDate.value.getMonth()) {
      updatedDates.push(new Date(currentDay))
      currentDay.setDate(currentDay.getDate() + 1)
    }
    return updatedDates
  }
})

const datesArrayMonth = computed(() => {
  if (dates.value !== undefined) {
    let updatedDatesArray: Array<Array<Date | null>> = [[], [], [], [], [], [], []]
    updatedDatesArray = updatedDatesArray.map((array, index) => {
      return dates.value?.filter((date) => {
        return index < 6 ? date.getDay() === index + 1 : date.getDay() === 0
      }) ?? []
    })
    // 1. Tag des Monats Sonntag -> weekdayIndex = 7
    const weekdayIndex = dates.value[0].getDay() === 0 ? 7 : dates.value[0].getDay()
    for (let i = 0; i < weekdayIndex - 1; i++)
      updatedDatesArray[i].unshift(null)
    return updatedDatesArray
  }
})

const datesArrayWeek = computed(() => {
  if (showDate.value !== undefined) {
    const updatedDatesArray: Array<Array<Date | null>> = [[], [], [], [], [], [], []]
    const tempValue = showDate.value

    // Bis Montag zurückspringen
    while (tempValue.getDay() !== 1)
      tempValue.setDate(tempValue.getDate() - 1)

    // Nächsten 7 Tage hinzufügen
    for (let i = 0; i <= 6; i++) {
      updatedDatesArray[i].push(new Date(tempValue))
      tempValue.setDate(tempValue.getDate() + 1)
    }
    return updatedDatesArray
  }
})

const calendarWeek = computed(() => {
  if (showDate.value)
    return getISOWeek(showDate.value)
})

const sizeStyle = computed(() => {
  switch (props.size) {
    case DATEPICKER_SIZE.LARGE:
      return 'datepicker-large'
    case DATEPICKER_SIZE.MEDIUM:
      return 'datepicker-medium'
    case DATEPICKER_SIZE.SMALL:
      return 'datepicker-small'
  }
})

const textStyle = computed(() => {
  switch (props.size) {
    case DATEPICKER_SIZE.LARGE:
      return 'text-2.5rem'
    case DATEPICKER_SIZE.MEDIUM:
      return 'text-2rem'
    case DATEPICKER_SIZE.SMALL:
      return 'text-1.5rem'
  }
})

const datePickerDayOuterSize = computed(() => {
  switch (props.size) {
    case DATEPICKER_SIZE.LARGE:
      return 'datepicker-day-large'
    case DATEPICKER_SIZE.MEDIUM:
      return 'datepicker-day-medium'
    case DATEPICKER_SIZE.SMALL:
      return 'datepicker-day-small'
  }
})

watch(value, (newValue: Date | undefined) => {
  if (!newValue) return
  showDate.value = newValue
}, {immediate: true})

function getSpecialDay(date: Date | null) {
  if (date !== null && dates.value !== undefined) {
    const day = date.getDate()
    const left = props.specialDays.find(x => dates.value ? isWithinInterval(dates.value[day - 2], {start: x.startDate, end: x.endDate}) : false) && date.getDay() !== 1
    const right = props.specialDays.find(x => dates.value ? isWithinInterval(dates.value[day], {start: x.startDate, end: x.endDate}) : false) && date.getDay() !== 0

    if (props.specialDays.find(x => dates.value ? isWithinInterval(dates.value[day - 1], {start: x.startDate, end: x.endDate}) : false)) {
      if (!left && right) // erster specialDay
        return 'start'
      if (left && right) // mittlerer specialDay
        return 'middle'
      if (left && !right)
        return 'end'
      if (!left && !right)
        return 'single'
    }
  }
}

function clickDay(date: Date) {
  value.value = date
}

function isActive(t: string) {
  switch (t) {
    case 'month':
      return type.value === DATEPICKER_TYPE.MONTH
    case 'week':
      return type.value === DATEPICKER_TYPE.WEEK
  }
}

function changeType(t: string) {
  switch (t) {
    case 'month':
      type.value = DATEPICKER_TYPE.MONTH
      break
    case 'week':
      type.value = DATEPICKER_TYPE.WEEK
  }

  emit('changeType', type.value)
}

function buttonClass(button: string) {
  switch (button) {
    case 'month':
      return type.value === DATEPICKER_TYPE.WEEK ? 'btn-inactive' : ''
    case 'week':
      return type.value === DATEPICKER_TYPE.MONTH ? 'btn-inactive' : ''
  }
}

function setShowDate(to: string) {
  if (showDate.value !== undefined) {
    if (type.value === DATEPICKER_TYPE.MONTH) {
      const newDate = new Date()
      switch (to) {
        case 'prev':
          newDate.setMonth(showDate.value.getMonth() - 1)
          newDate.setDate(1)
          showDate.value = newDate
          break
        case 'today':
          showDate.value = new Date()
          value.value = new Date()
          break
        case 'next':
          newDate.setMonth(showDate.value.getMonth() + 1)
          newDate.setDate(1)
          showDate.value = newDate
      }
    }

    else if (type.value === DATEPICKER_TYPE.WEEK && value.value !== undefined) {
      switch (to) {
        case 'prev':
          if (showDate.value.getDay() === 1) // Montag
            showDate.value.setDate(showDate.value.getDate() - 2)
          while (showDate.value.getDay() !== 1) { // Bis letzten Montag springen
            showDate.value.setDate(showDate.value.getDate() - 2)
          }
          showDate.value = new Date(showDate.value)
          break
        case 'today':
          showDate.value = new Date()
          value.value = new Date()
          break
        case 'next':
          if (showDate.value.getDay() === 1) // Montag
            showDate.value.setDate(showDate.value.getDate() - 1)
          while (showDate.value.getDay() !== 1) { // Bis nächsten Montag springen
            showDate.value.setDate(showDate.value.getDate() + 1)
          }
          showDate.value = new Date(showDate.value)
          break
      }
    }
  }
}

provide('datePickerDaySize', props.size)
</script>

<style lang="scss">
.h-fit {
    height: fit-content;
}

.text-2\.5rem {
    font-size: 2.5rem;
}

.text-2rem {
    font-size: 2rem;
}

.text-1\.5rem {
    font-size: 1.5rem;
}

.origin-center-top {
    transform-origin: center top;
}

.origin-top-left {
    transform-origin: top left;
}

.flex-1 {
    flex-shrink: 1;
    flex-grow: 1;
}

.w-1\/7 {
    width: 14.2856%;
}

.i-tabler-arrow-right {
    // ?
}

.datepicker-small {
    width: 35rem;
}
.datepicker-medium {
    width: 42rem;
}
.datepicker-large {
    width: 49rem;
}

.datepicker-day-small {
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
    min-height: 5rem;
}
.datepicker-day-medium {
    width: 6rem;
    min-width: 6rem;
    height: 6rem;
    min-height: 6rem;
}
.datepicker-day-large {
    width: 7rem;
    min-width: 7rem;
    height: 7rem;
    min-height: 7rem;
}

#controls {
  .btnControls {
      &:enabled, &:focus, &:active {
          outline: 0;
      }
  }
  .btn-inactive {
      border: 1px solid gray;
  }
  #btnMonth {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
  #btnWeek {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
  #btnPrev {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
  #btnToday {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-left: 0;
      border-right: 0;
  }
  #btnNext {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
}
</style>
