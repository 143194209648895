import 'bootstrap';
import 'lazysizes';
import $ from 'jquery';
window.$ = window.jQuery = $;

import Datepicker from './../vue/datepicker/DatePicker.vue';
import { createApp } from 'vue/dist/vue.esm-bundler';
import axios from "axios";

function isDisabled(date) {
    return date < new Date(2023, 8, 11) || date > new Date(2023, 8, 15)

}

const app = createApp(Datepicker, { showControls: false, showDate: new Date(2023, 8, 1), modelValue: new Date(2023, 8, 11), isDisabled: isDisabled });
app.mount("#app");

// handle contact form
$('#contact-form').submit(function (event) {
    event.preventDefault();
    let formData = new FormData();
    formData.append('firstname', $("#contact-form input[name='firstname']").val());
    formData.append('lastname', $("#contact-form input[name='lastname']").val());
    formData.append('email', $("#contact-form input[name='email']").val());
    formData.append('company', $("#contact-form input[name='company']").val());
    formData.append('message', $("#contact-form textarea[name='message']").val());
    formData.append('token', grecaptcha.getResponse());
    formData.append('locale', $("#lang-info").val());


    if (grecaptcha.getResponse()) {
        document.getElementById('contact-spinner').classList.remove('d-none');
        axios.post(APP_URL + '/api/contact', formData).then(response => {
            document.getElementById('contact-spinner').classList.add('d-none')
            document.getElementById('contact-form-success').classList.remove('d-none')
        }
        )
    }


});

// handle navbar
const scrollBox = document.getElementById('scroll-box');
const page1 = document.getElementById('p-1');
const page2 = document.getElementById('p-2');
const page31 = document.getElementById('p-3.1');
const page32 = document.getElementById('p-3.2');
const page33 = document.getElementById('p-3.3');
const page4 = document.getElementById('p-4');
const scrollBoxHeight = scrollBox.offsetHeight;


document.querySelectorAll('.home').forEach(function (element) {
    element.addEventListener('click', function () {
        if (window.innerWidth < 992) {
            page1.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start'
            });
        } else {
            scrollBox.scroll(0, 0);
        }
    });
});
document.querySelectorAll('.termin-buchen').forEach(function (element) {
    element.addEventListener('click', function () {
        if (window.innerWidth < 992) {
            page2.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start'
            });
        } else {
            scrollBox.scroll(0, page1.getBoundingClientRect().height);
        }
    });
});

document.querySelectorAll('.produkte').forEach(function (element) {
    element.addEventListener('click', function () {
            page31.scrollIntoView({
                block: 'end',
                behavior: 'smooth',
                inline: 'end'
            });
    });
});

document.querySelectorAll('.produkte2').forEach(function (element) {
    element.addEventListener('click', function () {
            page32.scrollIntoView({
                block: 'end',
                behavior: 'smooth',
                inline: 'end'
            });
        
    });
});
document.querySelectorAll('.produkte3').forEach(function (element) {
    element.addEventListener('click', function () {
      
            page33.scrollIntoView({
                block: 'end',
                behavior: 'smooth',
                inline: 'end'
            });
    });
});

document.querySelectorAll('.kontakt').forEach(function (element) {
    element.addEventListener('click', function () {
        if (window.innerWidth < 992) {
            page4.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start'
            });
        } else {
            scrollBox.scroll(0, page1.getBoundingClientRect().height + page2.getBoundingClientRect().height + page31.getBoundingClientRect().height + page32.getBoundingClientRect().height + page33.getBoundingClientRect().height);
        }
    });
});


function getWidgetId(elementId) {
    const recaptchaBoxes = document.querySelectorAll('.g-recaptcha');
    const targetBox = document.querySelector(`#${elementId}`);
    for (let i = 0; i < recaptchaBoxes.length; i++) {
        if (recaptchaBoxes[i].id === targetBox.id) {
            return i;
        }
    }
}


// handle appointment form
$('#appointment-form').submit(function (event) {
    event.preventDefault();
    let formData = new FormData();

    formData.append('time', $("#appointment-form select[name='time']").val());
    formData.append('firstname', $("#appointment-form input[name='firstname']").val());
    formData.append('lastname', $("#appointment-form input[name='lastname']").val());
    formData.append('email', $("#appointment-form input[name='email']").val());
    formData.append('company', $("#appointment-form input[name='company']").val());
    formData.append('date', $("#appointment-form input[name='appointment-date']").val());
    formData.append('token', grecaptcha.getResponse(getWidgetId('recaptcha-appointment')));
    formData.append('locale', $("#lang-info").val());


    if (grecaptcha.getResponse(getWidgetId('recaptcha-appointment'))) {
        document.getElementById('appointment-spinner').classList.remove('d-none');

        axios.post(APP_URL + '/api/appointment', formData).then(function (response) {
            if (response.status === 200) {
                document.getElementById('slide-1').classList.add('d-none');
                document.getElementById('slide-2').classList.remove('d-none');
                document.getElementById('close-appointment-modal').click()
            } else {

            }
            document.getElementById('appointment-spinner').classList.add('d-none');

        })
    }


});
