import type {ExtractPropTypes, PropType} from 'vue'

export const DATEPICKER_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

export type DatePickerSize = typeof DATEPICKER_SIZE[keyof typeof DATEPICKER_SIZE]

export const DATEPICKER_TYPE = {
  WEEK: 'week',
  MONTH: 'month',
}

export type DatePickerType = typeof DATEPICKER_TYPE[keyof typeof DATEPICKER_TYPE]

export function isWeekend(date: Date) {
  return date.getDay() === 0 || date.getDay() === 6
}

export const datePickerProps = {
  modelValue: {
    type: Date,
  default: new Date(),
  },
  showDate: {
    type: Date,
    default: new Date(),
  },
  size: {
    type: String as PropType<DatePickerSize>,
    default: DATEPICKER_SIZE.MEDIUM,
  },
  type: {
    type: String as PropType<DatePickerType>,
    default: DATEPICKER_TYPE.MONTH,
  },
  isDisabled: {
    type: Function as PropType<(date: Date | null) => boolean>,
    default: (date: Date | null) => {
      return date !== null && (isWeekend(date))
    },
  },
  specialDays: {
    type: Array as PropType<any>,
    default: [],
  },
  showControls: {
    type: Boolean,
    default: true,
  },
}

export type DatePickerProps = ExtractPropTypes<typeof datePickerProps>

export const cyDatePicker = 'datePicker'
export const cyDatePickerTitle = 'datePickerTitle'
export const cyDatePickerWeek = 'datePickerWeek'
export const cyDatePickerMonth = 'datePickerMonth'
export const cyDatePickerInner = 'datePickerInner'
export const cyBtnType = 'btnType'
export const cyBtnView = 'btnView'
export const cyBtnMonth = 'btnMonth'
export const cyBtnWeek = 'btnWeek'
export const cyBtnPrev = 'btnPrev'
export const cyBtnToday = 'btnToday'
export const cyBtnNext = 'btnNext'

export const DATEPICKER_DAY_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

export type DatePickerDaySize = typeof DATEPICKER_DAY_SIZE[keyof typeof DATEPICKER_DAY_SIZE]

export const SPECIAL_DAY_TYPE = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end',
  SINGLE: 'single',
}

export type SpecialDayType = typeof SPECIAL_DAY_TYPE[keyof typeof SPECIAL_DAY_TYPE]

export const datePickerDayProps = {
  date: {
    type: Date,
    default: null,
  },
  size: {
    type: String as PropType<DatePickerDaySize>,
    default: DATEPICKER_DAY_SIZE.MEDIUM,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  specialDay: {
    type: String as PropType<SpecialDayType>,
  },
  messages: {
    type: Array,
    default: [],
  },
  numberFoodReceiverOrdered: {
    type: Number,
    default: 0,
  },
}

export type DatePickerDayProps = ExtractPropTypes<typeof datePickerDayProps>

export const cyDatePickerDay = 'datePickerDay'
export const cyDatePickerDayInner = 'datePickerDayInner'
