<template>
  <div
    v-if="props.date !== null"
    :class="[specialDayStyle, disabledStyle, 'd-flex h-full']"
    :data-cy="cyDatePickerDay"
  >
    <div
      :class="[sizeStyle, 'border border-light border-3 fw-bold rounded-circle d-flex m-auto',
               {'bg-light': (props.disabled)},
               {' border-primary cursor-pointer text-primary ': (!props.disabled) || props.selected},]"
      :data-cy="cyDatePickerDayInner"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @click="emitDate"
    >
      <div class="text-center m-auto">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DATEPICKER_SIZE,
  SPECIAL_DAY_TYPE,
  cyDatePickerDay,
  cyDatePickerDayInner,
  datePickerDayProps,
} from './interface'
import {computed, ref} from "vue";

const props = defineProps(datePickerDayProps)
const emit = defineEmits<{
  (e: 'click', date: Date): void
}>()

const isHover = ref(false)

function emitDate() {
  if (!props.disabled)
    emit('click', props.date)
}

const sizeStyle = computed(() => {
  switch (props.size) {
    case DATEPICKER_SIZE.SMALL:
      return 'day-small'
    case DATEPICKER_SIZE.MEDIUM:
      return 'day-medium'
    case DATEPICKER_SIZE.LARGE:
      return 'day-large'
  }
})

const specialDayStyle = computed(() => {
  switch (props.specialDay) {
    case SPECIAL_DAY_TYPE.START:
      return 'special-day-start'
    case SPECIAL_DAY_TYPE.MIDDLE:
      return 'special-day-middle'
    case SPECIAL_DAY_TYPE.END:
      return 'special-day-end'
    case SPECIAL_DAY_TYPE.SINGLE:
      return 'special-day-single'
  }
})

const disabledStyle = computed(() => ({
  'text-gray-200': props.disabled,
  'text-blue-500': !props.disabled,
}))

function handleMouseEnter() {
  isHover.value = true
}

function handleMouseLeave() {
  isHover.value = false
}
</script>

<style>
.day-small {
    width: 3.7rem;
    height: 3.7rem;
    font-size: 1.7rem;
}
.day-medium {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 2rem;
}
.day-large {
    width: 5.5rem;
    height: 5.5rem;
    font-size: 2.5rem;
}
.special-day-start {
    background-color: yellow;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
}
.special-day-middle {
    background-color: yellow;
}
.special-day-end {
    background-color: yellow;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
}
.special-day-single {
    background-color: yellow;
    border-radius: 999px;
}
</style>
